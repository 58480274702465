const SOCIAL_PAGES = [
    {
        "name" : "facebook",
        "link" : "https://www.facebook.com/people/Tenetix-Academy/61556901641402/",
        "imgUrl" : "images/social/facebook.png",
        "bw-social-imgUrl" : "images/social/bw/facebook.png",
    },
    {
        "name" : "instagram",
        "link" : "https://www.instagram.com/tenetix.academy/",
        "imgUrl" : "images/social/instagram.png",
        "bw-social-imgUrl" : "images/social/bw/instagram.png",
    },
    {
        "name" : "linkedin",
        "link" : "https://www.linkedin.com/company/tenetix/",
        "imgUrl" : "images/social/linkedin.png",
        "bw-social-imgUrl" : "images/social/bw/linkedin.png",
    },
    {
        "name" : "youtube",
        "link" : "https://www.youtube.com/@TENETIXACADEMY",
        "imgUrl" : "images/social/youtube.png",
        "bw-social-imgUrl" : "images/social/bw/youtube.png",
    }
]

export { SOCIAL_PAGES }
const DATA_URL = {
    mailSend : "https://tenetix.ai:3523/api/mailSender"
}

class MailSender{
    static async sendMail(data){
        return fetch(
            DATA_URL.mailSend, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            }
        )
        .then(response => {
            if (!response.ok) {
                throw new Error('Failed to send mail');
            }
            return response.json();
        })
        .catch(error => {
            console.error(error);
            throw error;
        });
    }
}

export { MailSender, DATA_URL }
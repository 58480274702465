
import './for-whom-this-course-section.css';

export function ForWhomThisCourseSection(){

    return(
        <section className='for-whom-this-course-section'>
            <h4 className='for-whom-this-course-section__header'>Для кого <span className='for-whom-this-course-section__header_white'>цей курс?</span></h4>
            <div className='course-container'>
                <div className='for-whom-this-course-section__container'>
                    <div className='for-whom-this-course-section__text-content'>
                        <p>
                            Цей курс призначений для професіоналів із усіх галузей, 
                            які хочуть зрозуміти та застосувати штучний інтелект 
                            у своїй роботі — будь то освіта, маркетинг, фінанси, 
                            кадри чи будь-яка інша сфера, на яку впливає штучний інтелект. 
                            <br/>
                            <br/>
                            <span className='bold-600'>
                            Знання програмування не потрібні 
                            </span> —
                            лише <span className='underline'>бажання</span> вчитися 
                            та <span className='underline'>розвиватися</span> в майбутньому!
                        </p>
                    </div>
                    <div className='for-whom-this-course-section__list-content'>
                        <ul>
                            <li>лідерів</li>
                            <li>креативних</li>
                            <li>сміливих</li>
                            <li>інноваторів</li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>
    );
}
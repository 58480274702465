import { MainDescription, ProgramAbout, CourceFor, Program, Quote, Lead, FAQ, OurCoursesAndServices } from "../components/pages/mainPage";

export function MainPage() {
    return(
        <>
            <MainDescription />
            <ProgramAbout />
            {/* <CourceFor /> */}
            {/* <Program /> */}
            <OurCoursesAndServices />
            <Quote />
            <Lead />
            {/* <FAQ /> */}
        </>
    )
}
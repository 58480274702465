
// import { LS_KEYS, LocalStorageService } from "./localStorage";
// import { SS_KEYS, SessionStorageService } from "./sessionStorage";

const DATA_URL = {
                  //Ukraine
                  VACANCIES_LIST_URL : "http://localhost:3001/api/vacancies",
                  ARTICLE_URL : "http://localhost:3001/api/article",
                  INDUSTRIES_URL : "/data/UK/industry.JSON",
                  SERVICES_URL_UK : "/data/UK/services.JSON",
                  PRIVACY_URL_UK : "/data/UK/PP.JSON",
                  TERM_OF_USE_URL_UK : "/data/UK/TermsOfUse.JSON",
                  COURSES_URL_LIST_UK : "/data/UK/CoursesUrlList.JSON",

                  //English
                  // VACANCIES_LIST_URL : "http://localhost:3001/api/vacancies",
                  // ARTICLE_URL : "http://localhost:3001/api/article",
                  // INDUSTRIES_URL_EN : "/data/EN/industry.JSON",
                  SERVICES_URL_EN : "/data/EN/services.JSON",
                  PRIVACY_URL_EN : "/data/EN/PP.JSON",
                  TERM_OF_USE_URL_EN : "/data/EN/TermsOfUse.JSON",
                  COURSES_URL_LIST_EN : "/data/EN/CoursesUrlList.JSON",
                };

class DataLoader{
    // static async set(path, storage, arrIndex = 0){
    //     return fetch(path)
    //     .then((response) => response.json())
    //     .then((data) => Object.values(data))
    //     .then((array) => {console.log(array);storage[0].set(storage[1], array[arrIndex])})
    //     .catch(() => alert("Sorry, services dosn`t work"))
    // }
    static async set(path, storage){
        return fetch(path)
        .then((response) => response.json())
        .then((data) => storage[0].set(storage[1], data))
        // .then((array) => {console.log(array);storage[0].set(storage[1], array)})
        .catch((e) => alert(`Sorry, services dosn't work ${e}`))
    }
}

export { DataLoader, DATA_URL }
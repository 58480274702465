import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import cn from 'classnames';
import { useStyle } from '../../hooks/useStyle';
import { useWidth } from '../../hooks/useWidth';
import { NavItem } from './navItem';
import { NAV_ITEMS } from './navbarItems';
import './header.css';

export function Header() {

    const styles = useStyle();
    const {currentWidth} = useWidth();
    const [burgerMenuActive, setBurgerMenuActive] = useState(false);

    const handleSetBurgerMenuActive= (e) => {
        e.stopPropagation();
        setBurgerMenuActive(!burgerMenuActive)
    }
    const menuRef = useRef(null);
    const handleClickOutside = (event) => {
        if (menuRef.current && !menuRef.current.contains(event.target)) {
            setBurgerMenuActive(false);
        }
    };
    useEffect(() => {
        document.addEventListener('click', handleClickOutside);
        return () => {
        document.removeEventListener('click', handleClickOutside);
        };
    }, []);

    return(
        <header>
            <div className='header_main-container main-container'>
                <Link className='header-title-link' to="/">
                    <h2 className='header-title font-face-fixedsys' style={{borderColor : styles[1]}}>TENETIX ACADEMY</h2>
                </Link>
                {
                    currentWidth <= 992 && !burgerMenuActive 
                    ? 
                    null
                    :
                    <div className='nav-container' ref={menuRef}>
                        <nav className='nav-bar'>
                            <ul className='nav-bar_list'>
                                {
                                    NAV_ITEMS.map(el => {
                                        return <NavItem key={el.name} items={el} />
                                    })
                                }
                            </ul>
                        </nav>
                    </div>
                }
                {
                    currentWidth <= 992
                    ?
                    <button className={cn('burgermenu-button', {'burger-menu-active':  burgerMenuActive})} 
                    onClick={(e) => handleSetBurgerMenuActive(e)}></button>
                    : 
                    ''
                }
            </div>
        </header>
    );
}
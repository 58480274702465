import useScrollPosition from './useScrollPosition';

export function useStyle(){

    const divArray = document.querySelectorAll('main > div');
    const currentScrollPosition = useScrollPosition();
    let divObj = {};
    let startPos = 0;
    let finishPos = 0;

    for(let i = 0; i < divArray.length; i++){
        const el = +window.getComputedStyle(divArray[i]).height.slice(0, -2);
        const backCol = window.getComputedStyle(divArray[i]).backgroundColor;
        const fontCol = window.getComputedStyle(divArray[i]).color;
        finishPos = startPos + el;
        divObj[`${i}`] = [startPos, el, finishPos, backCol, fontCol];
        startPos = finishPos;
    }
    for (const key in divObj) {
        if(currentScrollPosition > divObj[key][0] && currentScrollPosition < divObj[key][2]){
            const backgroundColor = divObj[key][3];
            const color = divObj[key][4];
            return [backgroundColor, color];
        }
    }

    return divObj;

}
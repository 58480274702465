import './program-about.css';

function returnListItem(props){
    return(
        <li dangerouslySetInnerHTML={{__html: props}}></li>
    )
}

export function ProgramAbout(){

    const listData = [
        '<span>Спеціальні програми:</span> наші курси розроблені для абсолютних початківців, і тих хто змінює кар’єру',
        '<span>Грамотність ШІ</span>: ми спрощуємо складні концепції, роблячи ШІ доступним для всіх.',
        '<span>Наставники-експерти</span>: навчайтеся безпосередньо у професіоналів галузі, які супроводжують вас на кожному кроці.',
        '<span>Інтеграція ветеранів</span>: спеціальна підтримка, щоб допомогти ветеранам реінтегруватися  на посади, де необхідні технічні знання.'
    ]

    return (
        <div className="program-about-section" id='about-anchor'>
            <div className="program-about-first-section"></div>
            <div className="program-about-second-section">
                <div className='main-container'>
                    <h2>У чому наша цінність?</h2>
                    <p>Наша місія зробити технічні знання та знання про штучний інтелект доступні кожному.</p>
                    <h5>Наш підхід:</h5>
                    <ul>
                        {
                            listData.map(el => (
                                returnListItem(el)
                            ))
                        }
                    </ul>
                </div>
            </div>
            <div className="absolute-about-conainer main-container">
                <div className='absolute-about-item-container'>
                    <h3>INNOVATION EXPERIENCE FREEDOM</h3>
                </div>
            </div>
        </div>
    );
}
import React, { useReducer } from "react";

export const initialState = {
    url: '',
};

export function reducer(state, action) {
    switch (action.type) {
        case "SET_URL":
          return { ...state, url: action.payload };
        default:
            return state;
    }
}
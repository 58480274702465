import { MainInfoSection } from "./mainInfoSection/MainInfoSection";
import { SecondPreventSection } from "./secondPreventSection";
import { ForWhomThisCourseSection } from "./forWhomThisCourseSection";
import './course-page.css'
import { WhatWillYouLearnSection } from "./whatWillYouLearnSection/WhatWillYouLearnSection";
import { ChatGPTSection } from "./chatGPTSection/ChatGPTSection";
import { AboutCourseProgramSection } from "./aboutCourseProgramSection";

export function CoursePage() {

    return (
        <div className="course-page-container" style={{"background-color": "#000"}}>
            <MainInfoSection />
            <SecondPreventSection />
            <ForWhomThisCourseSection />
            <WhatWillYouLearnSection />
            <ChatGPTSection />
            <AboutCourseProgramSection />
        </div>
    );
}

import annaPhoto from '../../../../../assets/anna.jpg';

export function LeadItem({data}){

    return (
        <div className="lead__item">
            <img src={annaPhoto} alt="Anna Daliuk" />
        </div>
    );
}
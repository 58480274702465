import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import uuid from 'react-uuid';
import { SocialItem } from './socialItem';
import { SOCIAL_PAGES } from './social';
import { SERVICES, COMPANY } from './footerNavItems';
import { LinkWithBackgroundComponent } from '../links/linkWithBackgroundComponent';
import { useLang } from '../../hooks/useLang';
import customFontWoff from '../../fonts/fixedsys-ligatures.ttf';
import './footer.css'

export function Footer(){

    const {currentLanguage} = useLang();
    const [footerText, setFooterText] = useState([
                'All rights reserved &copy; 2023-2024 TENETIX ACADEMY',
                'Yaroslaviv Val, 7',
                'Kyiv, Ukraine']);

    useEffect(() => {
        if(currentLanguage === 'EN'){
            setFooterText([
                'All rights reserved &copy; 2023-2024 TENETIX ACADEMY',
                'Yaroslaviv Val, 7',
                'Kyiv, Ukraine']);
        }else if(currentLanguage === 'UK'){
            setFooterText([
                'Всі права захищені &copy; 2023-2024 TENETIX ACADEMY',
                'Ярославів вал, 7',
                'Київ, Україна']);
        }
    }, [currentLanguage]);

    const location = useLocation();
    const isNotGetInTouchPage = location.pathname !== "/get-in-touch";
    const newsChanel = {"url": "https://web.telegram.org/", "text": footerText[2], "margin": "5px 15px 5px 5px"}

    let footerStyle;
    if (isNotGetInTouchPage) {
        footerStyle = {}
    } else {
        footerStyle = {"minHeight": "0vh", "backgroundPosition": "bottom"}
    }

    const fontStyles = `
        @font-face {
            font-family: 'CustomFont';
            src: url(${customFontWoff}) format('woff');
        }
    `;

    return(
        <footer style={footerStyle}>
            <div className='main-container'>
                <div className='footer-container'>
                    <div className='footer-image-container'>
                        <style>{fontStyles}</style>
                        <svg width="320" height="100" xmlns="http://www.w3.org/2000/svg" style={{position: "absolute", bottom: "0"}}>
                            <rect width="100%" height="100%" fill="none" />
                            <text x="32%" y="50%" dominantBaseline="middle" fontFamily="'CustomFont'" fontWeight="bold" textAnchor="middle" fill="white" fontSize="26">
                                TENETIX ACADEMY
                            </text>
                        </svg>
                    </div>
                    <div className='footer-sections-container'>
                        
                        <div className=''>
                            <h5 className='footer-item_title'>SERVICES</h5>
                            {
                                SERVICES.map((el) => (
                                    <Link key={uuid()} to={el.link}>{el.name}</Link>
                                ))
                            }
                        </div>
                        <div className=''>
                            <h5 className='footer-item_title'>COMPANY</h5>
                            {
                                COMPANY.map((el) => (
                                    <Link key={uuid()} to={el.link}>{el.name}</Link>
                                ))
                            }
                        </div>
                        <div className=''>
                            <h5 className='footer-item_title'>CONTACT</h5>
                            <p>+38-063-776-6423</p>
                            <p>academy@tenetix.ai</p>
                            <p>{footerText[1]}</p>
                            <p>{footerText[2]}</p>
                        </div>
                    </div>
                </div>
                <hr/>
                <div className='footer-footer'>
                    <div>
                        <p dangerouslySetInnerHTML={{__html: footerText[0]}}></p>
                    </div>
                    <div className='footer_social-container'>
                        {SOCIAL_PAGES.map((el => <SocialItem key={el.name} data={el} />))}
                    </div>
                </div>
            </div>
        </footer>
    );
}

import { useState, useEffect } from 'react';
import { DataLoader, DATA_URL } from '../../../services/dataLoader';
import { SessionStorageService, SS_KEYS } from '../../../services/sessionStorage';
import { BlogItem } from './blogItem';
import titleImage2 from '../../../assets/events-title-bg.jpg'
import './blog-list.css';

export function BlogList() {

    const [blogList, setBlogList] = useState(SessionStorageService.get(SS_KEYS.BLOG_LIST) || null);

    const changeActiveButton = (e) => {
        if(e.target.className === 'blog-list__menu-button__active') return;
        const elements = document.getElementsByClassName('blog-list__menu-button__active');
        Array.from(elements).forEach(element => {
            element.classList.remove('blog-list__menu-button__active');
        });
        e.target.classList.add('blog-list__menu-button__active');
    }

    useEffect(() => {
        const blogListLoader = async () => {
            await DataLoader.set(DATA_URL.ARTICLE_URL, [SessionStorageService, SS_KEYS.BLOG_LIST]);
            setBlogList(SessionStorageService.get(SS_KEYS.BLOG_LIST));
        }
        blogListLoader();
    }, []);

    return(
        <div className='padding-container'>
            <section className='blog-list'>
                <h2 style={{backgroundImage: `url(${titleImage2})`, backgroundSize: "cover"}} className='blog-list__title'>TENETX`s Courses & Events</h2>
                <div className='blog-list__event-navigation-menu'>
                    <button onClick={(e) => changeActiveButton(e)} className='blog-list__menu-button blog-list__menu-button__active'>All</button>
                    <button onClick={(e) => changeActiveButton(e)} className='blog-list__menu-button'>Courses</button>
                    <button onClick={(e) => changeActiveButton(e)} className='blog-list__menu-button'>Events</button>
                </div>   
                <div className='blog-list__articles-container'>
                    {
                        blogList
                        ?
                        blogList.map(el => <BlogItem key={el["_id"]} data={el} />)
                        :
                        ""
                    }
                </div>
            </section>
        </div>
    );
}
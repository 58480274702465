import './quote.css'

export function Quote(){


    return(
        <div className="quote">
            <div className="quote__first-section"></div>
            <div className="quote__second-section">
            </div>
            <div className="quote__absolute-conainer main-container">
                <h2>«У чому наша цінність?»</h2>
                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Exercitationem vitae sapiente voluptatem.</p>
                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Ad corporis asperiores incidunt recusandae pariatur sint obcaecati deleniti quae? Asperiores exercitationem, id veniam autem maxime alias officia omnis cumque possimus tempore?</p>
                <span>Lorem, ipsum.</span>
            </div>
        </div>
    );
}
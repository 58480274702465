import uuid from 'react-uuid';

import { LeadItem } from "./leadItem/LeadItem";

import "./lead.css"


export function Lead() {

    const cardItems = ["d", "d", "g"]

    return (
        <div className="lead">
            <div className="lead__container main-container">
                <h2><span style={{"color":"#FFF"}}>Команда, що працює для вас.</span></h2>
                <div className="lead__card-container">
                    {
                        cardItems.map(el => (
                            <LeadItem data={el} key={uuid()}/>
                        ))
                    }
                </div>
            </div>
        </div>
    );
}


import './chat-gpt-section.css';

export function ChatGPTSection(){

    return(
        <section className='chat-gpt-section'>
            <h4 className='chat-gpt-section__title'>
                РОЗМОВЛЯТИ З <span className='chat-gpt-section__title_blue'>ChatGPT </span>НА ТИ
            </h4>
            <div className='chat-gpt-section__video-container'>
                <video src="">

                </video>
            </div>
        </section>
    );
}

import { Link } from 'react-router-dom';
// import itemImage from '../../../../assets/AutomationPowerIMG.png'
import './blog-item.css';

export function BlogItem(props) {

    const data = props.data;

    const backgroundImageStyle = {
        background: `url(${data["header-section"]["small-image"]})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        height: '100%',
        width: '100%',
        overflow: 'hidden',
    };

    return(
        <div className="blog-item">
            <div className="blog-item__blog-image-container" style={backgroundImageStyle}>
                {/* <img src={data["header-section"]["small-image"]} alt="" className="blog-item__blog-image" /> */}
                {data["header-section"]["category"]
                ?
                <p className="blog-item__blog-image-category">{data["header-section"]["category"]}</p>
                :
                ""}      
            </div>
            <Link to={`/article/${data["_id"]}`}><h3 className="blog-item__title">{data["header-section"]["main-title"]}</h3></Link>
            {/* <Link to="/article"><h3 className="blog-item__title">{data["header-section"]["main-title"]}</h3></Link> */}
            {/* <p className="blog-item__date">{data["header-section"]["date-publication"]}</p> */}
            <p className="blog-item__offline-online">{data["header-section"]["online-ofline"]}</p>
        </div>
    );

};
import { AboutUs } from "../components/pages/aboutUs"; 

export function AboutUsPage(){

    return(
        <>
            <AboutUs />
            {/* <Vacancies/> */}
        </>
    );
}
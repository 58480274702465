
// import './about-course-program-item.css';

import { useEffect } from "react";

export function AboutCourseProgramItem(props){

    const data = props.data;

    useEffect(() => {
        console.log(data)
    })

    return(
        <div className='item'>
            <div className='item__count'>{data['count']}</div>
            <div className='item__text'>
                <p><span>{data['title-text']}</span><br />{data['text']?data['text']:null}</p>
            </div>
        </div>
    );
}
import React, { useEffect, useState } from "react";
import "./privacy-policy.css";
import { SS_KEYS, SessionStorageService } from "../../../../services/sessionStorage";
import { DATA_URL, DataLoader } from "../../../../services/dataLoader";
import uuid from "react-uuid";
import { useLang } from "../../../../hooks/useLang";

export function PrivacyPolicy() {

    const [privacyData, setPrivacyData] = useState(SessionStorageService.get(SS_KEYS.PRIVACY_DATA) || null);

    const {currentLanguage} = useLang();

    useEffect(() => {
        const privacyDataLoader = async () => {
            await DataLoader.set(DATA_URL[`PRIVACY_URL_${currentLanguage}`], [SessionStorageService, SS_KEYS.PRIVACY_DATA]);
            setPrivacyData(SessionStorageService.get(SS_KEYS.PRIVACY_DATA));
        }
        privacyDataLoader();
    }, []);

    return(
        <div className="padding-container">
            {
                privacyData ?
                <section className="privacy-policy main-container">

                    <h3 className="privacy-policy__title-text" dangerouslySetInnerHTML={{__html: privacyData["title"]}}></h3>
                    <p className="privacy-policy__overview-text" dangerouslySetInnerHTML={{__html: privacyData["overview-text"]}}></p>
                    <div className="privacy-policy__main-text-container">
                        {privacyData["main-text"].map(el => 
                                <p key={uuid()} dangerouslySetInnerHTML={{__html: el}}></p>
                        )}
                    </div>
                    <div className="privacy-policy__list-text-container">
                        {
                            Object.keys(privacyData["list-text"]).map(obEl => (
                                <React.Fragment key={uuid()}>
                                    <h3 dangerouslySetInnerHTML={{__html: obEl}}></h3>
                                    <p dangerouslySetInnerHTML={{__html: privacyData["list-text"][obEl]}}></p>
                                </React.Fragment>
                            ))
                        }
                    </div>
                </section>
                : ""
            }
        </div>
    );
}
import React, { useState } from 'react';
import { MailSender } from '../../../../services/mailSend';
import './standart-form.css';

export function StandartForm() {

    const [PP, setPP] = useState(false)

    const getInTouchText = [
        'Звернутись до нас',
        'Усі поля обов’язкові, якщо не позначено як необов’язкові',
        'Ім`я',
        'Номер телефону (не обов`язково):',
        'Назва організації (не обов`язково):',
        'Назва організації',
        'Напишіть ваше повідомлення тут...',
        'Я прочитав і приймаю <a href="/#/terms-of-use">Умови використання</a> і <a href="/#/privacy-policy">Політику конфіденційності</a>*',
        'Надіслати'
    ];

    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone : '',
        organizationName : '',
        comment: '',
        cv: null,
        termsAccepted: false,
    });
    
    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        const inputValue = type === 'checkbox' ? checked : value;

        setFormData((prevData) => ({
        ...prevData,
        [name]: inputValue,
        }));
    };
    
    const sendEmail = (e) => {
        e.preventDefault();        
        MailSender.sendMail(formData);
        setFormData({
            name: '',
            email: '',
            phone : '',
            organizationName : '',
            comment: '',
            cv: null,
            termsAccepted: false,
        });
    }
    
    return(
        <>
            <form className='standart_form' onSubmit={(e) => sendEmail(e)} >
                <h2>{getInTouchText[0]}</h2>
                <p>{getInTouchText[1]}</p>
                <label>
                {getInTouchText[2]}
                    <input type="text" name="name" value={formData.name} onChange={handleChange} required />
                </label>
                <label>
                    Email
                    <input type="email" name="email" value={formData.email} onChange={handleChange} required />
                </label>


                <label htmlFor='phoneNumber'>{getInTouchText[3]}
                    <input type='tel'
                           name="phone"
                           onChange={handleChange}
                           value={formData.phone}
                           placeholder='+380631234567'
                           maxLength='13' />
                </label>
                <label htmlFor='organizationName'>{getInTouchText[4]}
                    <input type='text'
                           name='organizationName'
                           onChange={handleChange}
                           value={formData.organizationName}
                           placeholder={getInTouchText[5]}/>
                </label>
                <label>
                    <textarea name="comment" value={formData.comment} onChange={handleChange} placeholder={getInTouchText[6]} required></textarea>
                </label>
                <div className='standart_form_button-container'>
                    <div className='standart__checkbox-container'>
                        <input
                            type="checkbox"
                            name="termsAccepted"
                            checked={formData.termsAccepted}
                            onChange={handleChange}
                            required
                            className='standart__checkbox-item'
                            onClick={() => setPP(!PP)}
                        />
                        <label dangerouslySetInnerHTML={{__html: getInTouchText[7]}}></label>
                        <button type="submit">{getInTouchText[8]}</button>
                    </div>
                </div>
            </form>
        </>
    );

}
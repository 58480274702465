import React, { useState, useEffect } from 'react';
import { MailSender } from '../../../../services/mailSend';
import { Link } from 'react-router-dom';
import './vacancies-form.css'

export function VacanciesForm() {
    
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        linkedIn: '',
        comment: '',
        cv: null,
        termsAccepted: false,
    });
    
    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        const inputValue = type === 'checkbox' ? checked : value;

        setFormData((prevData) => ({
        ...prevData,
        [name]: inputValue,
        }));
    };
    
    const handleFileChange = (e) => {
        const file = e.target.files[0];
        setFormData((prevData) => ({
        ...prevData,
        cv: file,
        }));
    };
    
    const sendEmail = (e) => {
        e.preventDefault();        
        MailSender.sendMail(formData);
        console.log(formData)
        setFormData({
            name: '',
            email: '',
            phone : '',
            organizationName : '',
            comment: '',
            cv: null,
            termsAccepted: false,
        });
    }

    return(
        <>
            <form className='vacancies_form' onSubmit={(e) => sendEmail(e)} >
                <h2>Apply now</h2>
                <p>All fields are required unless marked optional</p>
                <label>
                    Name
                    <input type="text" name="name" value={formData.name} onChange={handleChange} required />
                </label>
                <label>
                    Email
                    <input type="email" name="email" value={formData.email} onChange={handleChange} required />
                </label>
                <label>
                    LinkedIn Profile (optional)
                    <input type="text" name="linkedIn" value={formData.linkedIn} onChange={handleChange} />
                </label>
                <label>
                    Comment (optional)
                    <textarea name="comment" value={formData.comment} onChange={handleChange}></textarea>
                </label>
                <div className='vacancies__checkbox-container'>
                    <input
                        type="checkbox"
                        name="termsAccepted"
                        checked={formData.termsAccepted}
                        onChange={handleChange}
                        required
                        className='vacancies__checkbox-item'
                    />
                    <label>
                        I have read and accepted the <Link to="/terms-of-use">Terms of Use</Link> and <Link to="/privacy-policy">Privacy Policy</Link>*
                    </label>
                </div>
                
                <div className='vacancies_form_button-container'>
                    <label className='attach-cv_button'>
                        Attach CV
                        <input type="file" name="cv" accept=".pdf, .doc, .docx" required onChange={handleFileChange} />
                    </label>
                    <button type="submit">Send</button>
                </div>
                <p className='vacancies__restrictions-text'>* less than 10 MB</p>
            </form>
        </>
    );

}
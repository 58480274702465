import { Link } from 'react-router-dom';

import './link-with-background.css';


export function LinkWithBackground(props){

    const data = props.data;
    const {url, text, background="", border="", borderRadius="", color="", fontSize="", margin="", padding="", width="", height=""} = data;

    const linkStyle = {
        "background" : background,
        "border" : border,
        "borderRadius" : borderRadius,
        "color" : color,
        "fontSize" : fontSize,
        "margin" : margin,
        "padding" : padding,
        '&:hover, &:focus, &:active, &:visited': {
            "color" : color
        },
        "width" : width,
        "height" : height
    }

    return(
        <>
            <Link to={url} className='link-with-background' style={linkStyle}>
                {text}
            </Link>
        </>
    );

}
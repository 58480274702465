import React, { useEffect, useState } from "react";
import { DATA_URL, DataLoader } from "../../../../services/dataLoader";
import { SS_KEYS, SessionStorageService } from "../../../../services/sessionStorage";
import { useLang } from "../../../../hooks/useLang";
import "./terms-of-use.css";
import uuid from "react-uuid";

export function TermsOfUse() {

    const [termOfUseData, setTermOfUseData] = useState(SessionStorageService.get(SS_KEYS.TERM_OF_USE_DATA) || null);

    const {currentLanguage} = useLang();

    useEffect(() => {
        const termOfUseDataLoader = async () => {
            await DataLoader.set(DATA_URL[`TERM_OF_USE_URL_${currentLanguage}`], [SessionStorageService, SS_KEYS.TERM_OF_USE_DATA]);
            setTermOfUseData(SessionStorageService.get(SS_KEYS.TERM_OF_USE_DATA));
        }
        termOfUseDataLoader();
    }, []);

    return(
        <div className="padding-container">
            {
                termOfUseData ?
                <section className="terms-of-use main-container">

                    <h3 className="terms-of-use__title-text" dangerouslySetInnerHTML={{__html: termOfUseData["title"]}}></h3>
                    <p className="terms-of-use__overview-text" dangerouslySetInnerHTML={{__html: termOfUseData["overview-text"]}}></p>
                    <div className="terms-of-use__main-text-container">
                        {termOfUseData["main-text"].map(el => 
                                <p key={uuid()} dangerouslySetInnerHTML={{__html: el}}></p>
                        )}
                    </div>
                    <div className="terms-of-use__list-text-container">
                        {
                            Object.keys(termOfUseData["list-text"]).map(el => (
                                <React.Fragment key={uuid()}>
                                    <h3 dangerouslySetInnerHTML={{__html: el}}></h3>
                                    {
                                        (typeof(termOfUseData["list-text"][el]) === "object" && Array.isArray(termOfUseData["list-text"][el]))
                                        ?
                                        termOfUseData["list-text"][el].map(innerEl => (
                                            <p key={uuid()} dangerouslySetInnerHTML={{__html: innerEl}}></p>
                                        ))
                                        :
                                        <p dangerouslySetInnerHTML={{__html: termOfUseData["list-text"][el]}}></p>
                                    }
                                </React.Fragment>
                            ))
                        }
                    </div>
                    <p className="terms-of-use__adjustment-text" dangerouslySetInnerHTML={{__html: termOfUseData["adjustment-text"]}}></p>
                    <p className="terms-of-use__update-text" dangerouslySetInnerHTML={{__html: termOfUseData["update-text"]}}></p>
                </section>
                : ""
            }
        </div>
    );
}

import { useWidth } from '../../../../hooks/useWidth';
import AIFaceAvatar from '../../../../assets/AIFace.png'
import './second-prevent-section.css';

export function SecondPreventSection(){

    const {width} = useWidth();
    
    return(
        <section className='second-section'>
            <div className='course-container'>
                <h4 className='second-section__header'>
                    НУ БУДЬТЕ <span className='second-section__header_color-white'>ЗАРУЧНИКАМИ НЕВІДОМОГО</span>
                </h4>
                <div className='second-section__content'>
                    <img src={AIFaceAvatar} alt="AI avatar" className='second-section__img' />
                    <div className='second-section__text-container'>
                        <p>
                            "Не будьте заручником невідомого — вивчайте штучний 
                            інтелект для ефективності, розвитку та інновацій."
                        </p>
                        {
                            width && width < 768 ?
                            <br/>
                            :
                            null
                        }
                        <p>
                            Розкривайте нові горизонти можливостей, використовуючи 
                            силу технологій для розв’язання складних задач. Штучний 
                            інтелект — це інструмент, що допомагає зосередитися на 
                            стратегічних цілях, автоматизуючи рутинні процеси. Ваш 
                            успіх починається з відкритості до змін і готовності 
                            навчатися новому.
                        </p>
                    </div>
                </div>
            </div>
        </section>
    );
}
import uuid from "react-uuid";
import './chess-section.css'
import { Link } from "react-router-dom";

export function ChessSection(props) {

    const data = props.data.courses;

    return(
        <section className="container white-background color-black" id='chess-section'>
            <div className='content'>
                <div className='content__chess-section-section'>
                    <h4 className='chess-content__section-title' >
                        НАШІ КУРСИ ТА ПОСЛУГИ
                    </h4>
                    {
                        data.map(el => (
                            <div key={uuid()} className='chess-section__item'>
                                <Link dangerouslySetInnerHTML={{__html: el["title"]}} to={el["url"]} ></Link>
                            </div>
                        ))
                    }
                </div>
            </div>
        </section>
    );
}
import { Link } from "react-router-dom";
import './social-item.css'
import { useState } from "react";

export function SocialItem(props){

    const data = props.data;
    const [socialImage, setSocialImage] = useState(data["bw-social-imgUrl"]);

    const handleMouseOver = () => {
        setSocialImage(data["imgUrl"]);
    }
    const handleMouseLeave = () => {
        setSocialImage(data["bw-social-imgUrl"]);
    }

    return(
        <Link to={data.link} 
              className="footer-item_link" 
              target="_blank">
            <img src={socialImage} 
                 alt={data.name} 
                 className="footer-item_link-img" 
                 onMouseOver={() => handleMouseOver()}
                 onMouseLeave={() => handleMouseLeave()}/>
        </Link>
    );
}

import { useWidth } from '../../../../hooks/useWidth';

import img1 from '../../../../assets/AI1.JPG';
import img2 from '../../../../assets/AI2.JPG';
import img3 from '../../../../assets/AI3.JPG';
import img4 from '../../../../assets/AI1.JPG';
import img5 from '../../../../assets/AI2.JPG';
import './what-will-you-learn-section.css';
import { useEffect, useState } from 'react';

export function WhatWillYouLearnSection(){
    
    const {currentWidth} = useWidth();
    const [gridWidth, setGridWidth] = useState();

    useEffect(() => {
        // console.log(currentWidth);
        const gridContainer = document.querySelector('.what-will-you-learn-section__content-container');
        // console.log(gridContainer)
        const gridHeight = parseFloat(window.getComputedStyle(gridContainer).width);
        if(currentWidth && currentWidth < 768) {
            setGridWidth(gridHeight);
        } else {
            setGridWidth(gridHeight * 0.75);
        }
        // setGridWidth(parseFloat(window.getComputedStyle(gridContainer).width) * 0.5)
        // console.log('grid width: ' + gridWidth)
    }, [currentWidth])



    return(
        <section className='what-will-you-learn-section'>
            <h4 className='what-will-you-learn-section__header'>ЩО <span className='what-will-you-learn-section__header_blue'>ВИ </span>НАВЧИТЕСЬ</h4>
            <div className='what-will-you-learn-section__content-container' style={{height: gridWidth}}>
                {
                    currentWidth && currentWidth < "768" 
                    ? 
                    <>
                        <div className='what-will-you-learn-section__grid-item1'></div>
                        <div className='what-will-you-learn-section__grid-item2'>
                            <div className='grid-text-container'>
                                <p>
                                    Lorem ipsum, dolor sit amet adipisicing elit.
                                </p>
                            </div>
                        </div>
                        <div className='what-will-you-learn-section__grid-item3'></div>
                        <div className='what-will-you-learn-section__grid-item4'></div>
                        <div className='what-will-you-learn-section__grid-item5'>
                            <div className='grid-text-container'>
                                <p>
                                    Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                                </p>
                            </div>
                        </div>
                        <div className='what-will-you-learn-section__grid-item6'></div>
                    </>
                    :
                    <>
                        <div className='what-will-you-learn-section__grid-item1'></div>
                        <div className='what-will-you-learn-section__grid-item2'>
                            <div className='grid-text-container'>
                                <p>
                                    Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                                </p>
                            </div>
                        </div>
                        <div className='what-will-you-learn-section__grid-item3'></div>
                        <div className='what-will-you-learn-section__grid-item4'></div>
                        <div className='what-will-you-learn-section__grid-item5'>
                            <div className='grid-text-container'>
                                <p>
                                    Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                                </p>
                            </div>
                        </div>
                        <div className='what-will-you-learn-section__grid-item6'></div>
                        <div className='what-will-you-learn-section__grid-item7'></div>
                    </>
                }
            </div>
        </section>
    );
}
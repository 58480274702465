import { LinkWithBackground } from "./whithBG/linkWithBackground";

export function LinkWithBackgroundComponent(props){

    const data = props.data;

    return(
        <>
            <LinkWithBackground data={data} />
        </>
    );
}
import React, { useEffect, useState } from 'react';

import { VacanciesForm } from './vacanciesForm';
import './vacancies-info.css'
import { Link, useParams } from 'react-router-dom';
import { SS_KEYS, SessionStorageService } from '../../../services/sessionStorage';
import { DATA_URL, DataLoader } from '../../../services/dataLoader';
import uuid from 'react-uuid';

export function VacanciesInfo() {
    
    const [vacanciesListData, setVacanciesListData] = useState(SessionStorageService.get(SS_KEYS.VACANCIES_LIST) || null);
    const [vacanciesData, setVacanciesData] = useState(SessionStorageService.get(SS_KEYS.VACANCIES_INFO) || null);
    const id = useParams();
    // const vacanciesUrl = `${DATA_URL.VACANCIES_LIST_URL}/${id.id}`;

    useEffect(() => {
        if(!vacanciesListData){
            const vacanciesDataLoader = async () => {
                await DataLoader.set(DATA_URL.VACANCIES_LIST_URL, [SessionStorageService, SS_KEYS.VACANCIES_LIST]);
                setVacanciesListData(SessionStorageService.get(SS_KEYS.VACANCIES_LIST));
            }
            vacanciesDataLoader();
        }
    }, []);

    useEffect(() => {
        if(vacanciesListData){

            const getVacanciesDataByID = (id) => {
                return vacanciesListData.find(el => el['_id'] === id.id);
            }
            setVacanciesData(getVacanciesDataByID(id));
        }
    }, [vacanciesListData, id]);
    return(
        <>
        <section  className='vacancies-info_section'>
            <div className='vacancies-info_container main-container'>
                <div className='vacancies-info_description-container'>
                    <Link className='description-container_back-button' to='/about-us#Careers'>&larr; Back</Link>
                    {
                        vacanciesData
                        ?
                        <>
                            <h1 className='description-container_title' dangerouslySetInnerHTML={{__html: vacanciesData.title}}></h1>
                            <div className='description-container_additional-info'>
                                {vacanciesData.stack ? <p dangerouslySetInnerHTML={{__html: vacanciesData.stack}}></p> : null}
                                {(vacanciesData.stack && vacanciesData.time) ? <p>&#10242;</p> : null}
                                {vacanciesData.time ? <p dangerouslySetInnerHTML={{__html: vacanciesData.time}}></p> : null}                              
                            </div>
                            {
                                vacanciesData["description"]
                                ?
                                vacanciesData["description"].map((el) => (
                                    <p className='description-container_description-text' dangerouslySetInnerHTML={{__html: el}}></p>
                                ))
                                :
                                null
                            }
                            {
                                vacanciesData["youWill"]
                                ?
                                <ul>
                                    <h3>In this role, you will:</h3>
                                    {vacanciesData["youWill"].map((el) => (
                                        <li key={uuid()} dangerouslySetInnerHTML={{__html: el}}></li>
                                    ))}
                                </ul>
                                :
                                null
                            }
                            {
                                vacanciesData["requirements"]
                                ?
                                <ul>
                                    <h3>Requirements:</h3>
                                    {vacanciesData["requirements"].map((el) => (
                                        <li key={uuid()} dangerouslySetInnerHTML={{__html: el}}></li>
                                    ))}
                                </ul>
                                :
                                null
                            }
                            {
                                vacanciesData["plus"]
                                ?
                                <ul>
                                    <h3>Will be a plus:</h3>
                                    {vacanciesData["plus"].map((el) => (
                                        <li key={uuid()} dangerouslySetInnerHTML={{__html: el}}></li>
                                    ))}
                                </ul>
                                :
                                null
                            }
                            {
                                vacanciesData["offer"]
                                ?
                                <ul>
                                    <h3>What we offer:</h3>
                                    {vacanciesData["offer"].map((el) => (
                                        <li key={uuid()} dangerouslySetInnerHTML={{__html: el}}></li>
                                    ))}
                                </ul>
                                :
                                null
                            }
                        </>
                        :
                        null
                    }
                </div>
                <div className='vacancies-info_form-container'>
                <VacanciesForm />
                </div>
            </div>
        </section>
        </>
    );

}
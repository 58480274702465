import { LinkWithBackgroundComponent } from '../../../links/linkWithBackgroundComponent';
import './main-description.css'
import air from '../../../../assets/air.svg'

export function MainDescription(){

    const descriptionLinks = [
        {'url' : 'https://www.instagram.com/tenetix.academy/', 'text' : 'ХОЧУ КОНСУЛЬТАЦІЮ', 'background' : '#0014C8', 'border' : '1px solid #458CF9', 'borderRadius' : '5px', 'color' : '#fff', 'fontSize' : '18px', 'padding' : '5px 30px', 'width' : '250px', 'height' : '60px'}, 
        {'url' : '/#about-anchor', 'text' : 'ДІЗНАТИСЬ БІЛЬШЕ', 'background' : '#fff', 'border' : '1px solid #000', 'borderRadius' : '5px', 'color' : '#000', 'fontSize' : '18px', 'padding' : '5px 30px', 'width' : '250px', 'height' : '60px'}
    ];

    return(
        <div className="main-description-section">
            <div className='main-description-container main-container'>
                <h1>Вивчай. 
                <br />Розвивайся. 
                    <br />
                    Досягай успіху.</h1>
                <h2>Стираємо горизонти між людьми та інноваційними 
                    технологіями через менторські програми, інтерактивні курси та 
                    сучасні інструменти
                </h2>
                <div className='main-description-img-section'>
                    <img src={air} alt='air' />
                </div>
                <div className='button-container'>
                    <LinkWithBackgroundComponent data={descriptionLinks[0]} />
                    <LinkWithBackgroundComponent data={descriptionLinks[1]} />
                </div>
            </div>
        </div>
    );
}

import { useWidth } from '../../../../hooks/useWidth';
import calendar from "../../../../assets/calendar.svg";
import online from '../../../../assets/online.svg'
import './main-info-section.css';
import { useEffect } from 'react';

export function MainInfoSection (props) {

    // if
    const {currentWidth} = useWidth();

    useEffect(() => {
        console.log(currentWidth);
    }, [currentWidth])

    return(
        <>
            <section className="main-info-section">
                <div className='course-container'>
                    <div className="main-info__header-section">
                        <div className="main-info__title">
                            <h3 className="main-info__title_blue">
                                GROWTH 
                                {

                                    currentWidth && currentWidth < "768" ?
                                    <br/>
                                    :
                                    null
                                }
                                <span>WITH THE POWER OF </span>
                                AI
                            </h3>
                        </div>
                        <div className="maim-info__additional-info">
                            <div className="additional-info__item">
                                <p className='additional-info__date'>16 грудня</p>
                                <img src={calendar} alt="calendar" className='additional-info__icon'/>
                            </div>
                            <div className="additional-info__item">
                                <p>Online</p>
                                <img src={online} alt="online" />
                            </div>
                        </div>
                    </div>
                    <div className="main-info__description-section">
                        <p>
                            Lorem ipsum dolor sit amet consectetur, adipisicing elit. 
                            <span className="main-info__keyword_blue">Placeat ea voluptate</span>  iste 
                            fugiat hic temporibus sint ipsa officiis amet deserunt obcaecati, 
                            natus nulla. Voluptatum ipsam, odio at corporis
                            pariatur dolor!
                        </p>
                        <button>
                            Спробувати курс
                        </button>
                        <p>
                            Lorem ipsum dolor sit amet consectetur adipisicing elit.
                            <span className="main-info__keyword_blue">
                            Facilis deserunt, impedit ipsam sed similique nisi, facere
                            </span> 
                            molestiae quod, unde earum corporis labore sit nostrum 
                            maxime optio? Commodi excepturi quia alias.
                        </p>
                    </div>
                </div>
            </section>
        </>
    )
}